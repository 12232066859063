import React from "react";
import { Typography, Box, Container } from "@mui/material";
import Faq from "react-faq-component";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const FaqPage = ({ datastore }) => {
  const config = {
    animate: true,
  };

  const styles = {
    bgColor: "primary.light",
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh" // Ensure full viewport height
      sx={{ textAlign: "center" }}
    >
      <Header />
      <Box flexGrow={1}>
        {/* Ensures that the footer sticks to the bottom */}
        <Container sx={{ pt: 4 }}>
          <Typography variant="h4">FAQ</Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Click below to view some of the commonly asked questions about our
            broomball event!
          </Typography>
        </Container>
        <Container>
          <Faq
            data={{ rows: datastore.config.faqs }}
            config={config}
            styles={styles}
          />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default FaqPage;
