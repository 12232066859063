import { makeAutoObservable, configure } from "mobx";
import ky from "ky";

configure({
  enforceActions: "never",
});

function isProduction() {
  const host = window.location.host;
  const isLocalhost = host.includes("localhost");
  const isIpAddress = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d+)?$/.test(host);
  return !isLocalhost && !isIpAddress;
}

class ConfigDatastore {
  constructor(name) {
    this.name = name;
    makeAutoObservable(this);
  }
  name;
  configPath = "/get/config";

  config = {};
  isLoading = true;
  isError = false;

  fetchConfig = () => {
    this.isLoading = true;
    ky.post(window._env_.REACT_APP_API_URL + this.configPath, {
      body: isProduction()
        ? window.location.host
        : window._env_.REACT_APP_HOST_OVERRIDE,
      headers: {
        "Content-Type": "text/plain",
      },
      timeout: window._env_.REACT_APP_TIMEOUT,
    })
      .json()
      .then((response) => {
        this.config = response;
        this.isLoading = false;
        this.isError = false;
      })
      .catch((error) => {
        this.config = {};
        this.isError = true;
        this.isLoading = false;
        console.log(error);
      });
  };
}
export default ConfigDatastore;
