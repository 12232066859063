const calculateAge = (birthdate, givenDate) => {
  const birth = new Date(birthdate);
  const date = new Date(givenDate);

  let age = date.getFullYear() - birth.getFullYear();
  const m = date.getMonth() - birth.getMonth();

  if (m < 0 || (m === 0 && date.getDate() < birth.getDate())) {
    age--;
  }

  return age;
};

const isPastDate = (dateString) => {
  const date = new Date(dateString);
  return date < new Date();
};

export { calculateAge, isPastDate };
