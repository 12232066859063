import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { Divider } from "@mui/material";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
        }}
      >
        <p>{`Date: ${label}`}</p>
        <p>{`Accumulated Income: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};

const NetIncomeChart = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const processedData = processData(data);
    setChartData(processedData);
  }, [data]);

  function formatValue(value) {
    const suffixes = ["", "k", "m", "b", "t"]; // suffixes for thousands, millions, billions, trillions
    let suffixIndex = 0;
    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }
    return Math.round(value) + suffixes[suffixIndex];
  }

  function convertDateFormat(dateString) {
    const [month, day, year] = dateString.split("/");
    const formattedYear = year.slice(-2);
    return `${Number(month)}/${Number(day)}/${formattedYear}`;
  }

  const processData = (data) => {
    const incomeByDate = {};

    let minDate = moment.utc().startOf("day"); // Default to today if no data
    let maxDate = moment.utc("1900-01-01");
    data.forEach((entry) => {
      if (!entry.isVoided && entry.registrationTime) {
        const date = moment.utc(entry.registrationTime);
        if (date.isValid()) {
          minDate = moment.min(minDate, date);
          maxDate = moment.max(maxDate, date);
        }
      }
    });

    // Subtract one day from the earliest date
    minDate.subtract(1, "days");

    // Adjust maxDate to include the current day in local timezone
    const localToday = moment();
    const todayUTC = moment.utc(localToday.format("YYYY-MM-DD"));
    maxDate = moment.max(maxDate, todayUTC);

    // Generate Dates and Initialize Income
    for (
      let m = moment.utc(minDate);
      m.isSameOrBefore(maxDate);
      m.add(1, "days")
    ) {
      incomeByDate[m.format("YYYY-MM-DD")] = 0;
    }

    // Aggregate Sales Data
    data.forEach((entry) => {
      if (!entry.isVoided && entry.netPaymentAmount && entry.registrationTime) {
        const date = moment.utc(entry.registrationTime);
        if (date.isValid()) {
          const formattedDate = date.format("YYYY-MM-DD");
          incomeByDate[formattedDate] += parseFloat(entry.netPaymentAmount);
        }
      }
    });

    const result = [];
    let accumulatedIncome = 0;

    // Sort and Process Dates for Output
    Object.keys(incomeByDate)
      .sort((a, b) => {
        return moment.utc(a).isBefore(moment.utc(b)) ? -1 : 1;
      })
      .forEach((date) => {
        accumulatedIncome += incomeByDate[date];
        result.push({
          date: moment.utc(date).format("MM/DD/YYYY"),
          accumulatedIncome,
        });
      });

    return result;
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <Accordion defaultExpanded={false} sx={{ borderRadius: "4px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            <strong>Income Graph</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{ marginBottom: 2.25 }} />
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => convertDateFormat(value)}
              />
              <YAxis tickFormatter={(value) => `$${formatValue(value)}`} />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                formatter={(value) => value.replace(/([A-Z])/g, " $1").trim()}
              />
              <Line
                type="monotone"
                dataKey="accumulatedIncome"
                name="Net Income"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default NetIncomeChart;
