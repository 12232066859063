import React from "react";
import { Typography, Box, Container, Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const RegisterClosed = () => {
  const navigation = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={{ textAlign: "center" }}
    >
      <Header />
      <Box flexGrow={1}>
        <Container sx={{ pt: 4 }}>
          <Typography variant="h4">Registration is Closed!</Typography>
          <Typography variant="body1" sx={{ mt: 3 }}>
            Sorry we missed you! Registration for this year's event is now
            closed.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, mb: 3 }}>
            Please check back around December for information about next year's
            event!
          </Typography>
          <Tooltip title="Redirect to Home" placement="bottom" arrow={true}>
            <Button
              variant="outlined"
              onClick={() => {
                navigation("/");
              }}
            >
              Home
            </Button>
          </Tooltip>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default RegisterClosed;
