import React, { useState } from "react";
import {
  TextField,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "awesome-phonenumber";
import { capitalize } from "lodash";
import { Stack } from "@mui/system";
import ky from "ky";

import { calculateAge } from "../../utils/dateUtils";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  birthdate: "",
  gender: "select",
  phone: "",
  unitType: "select",
  unitNumber: "",
  emergencyContactName: "",
  emergencyContactRelation: "",
  emergencyContactPhone: "",
  shirtSize: "select",
};
const Form = ({ datastore }) => {
  let navigate = useNavigate();

  const [formValues, setFormValues] = useState(defaultValues);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emergencyPhoneError, setEmergencyPhoneError] = useState(false);
  const today = new Date();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If email field is being updated, validate the email
    if (name === "email") {
      setEmailError(!isValidEmail(value));
      setFormValues({
        ...formValues,
        [name]: value,
      });
    } else if (name === "firstName" || name === "lastName") {
      setFormValues({
        ...formValues,
        [name]: capitalize(value),
      });
    } else if (name === "phone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      if (pn.valid) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else if (name === "emergencyContactPhone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      if (pn.valid) {
        setEmergencyPhoneError(false);
      } else {
        setEmergencyPhoneError(true);
      }
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else if (name === "birthdate") {
      const dateOfBirth = new Date(value);
      const today = new Date();

      // Check if the date of birth is in the future or if the participant will be at least 14 on the event date
      if (
        dateOfBirth > today ||
        calculateAge(value, datastore.config.eventDate) < 14
      ) {
        setAlertLevel("error");
        setAlertMessage(
          "Please make sure the birthdate is valid and the person will be at least 14 years old on the date of the event.",
        );
        setAlertIsOpen(true);
      } else {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Box
      component="form"
      sx={{
        mt: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        value={formValues.firstName}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        value={formValues.lastName}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      {/* TODO: Validate email by sending email?*/}
      <TextField
        error={emailError}
        helperText={
          emailError
            ? "Invalid email"
            : "This is where your receipt will be sent."
        }
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        value={formValues.email}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        fullWidth
        id="birthdate-input"
        name="birthdate"
        label="Birthdate"
        helperText="Must be at least 14 years old."
        type="date"
        value={formValues.birthdate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true, required: true }}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        select
        margin="normal"
        fullWidth
        SelectProps={{
          native: true,
        }}
        required
        value={formValues.gender}
        label="Gender"
        name="gender"
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      >
        <option value="select" disabled={true}>
          Select
        </option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </TextField>
      <TextField
        margin="normal"
        fullWidth
        id="phone"
        label="Phone Number"
        helperText="10 digit phone number"
        name="phone"
        value={formValues.phone}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        select
        margin="normal"
        fullWidth
        SelectProps={{
          native: true,
        }}
        required
        value={formValues.unitType}
        label="Unit Type"
        name="unitType"
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      >
        <option value="select" disabled={true}>
          Select
        </option>
        <option value="Troop">Troop</option>
        <option value="Crew">Crew</option>
        <option value="Ship">Ship</option>
      </TextField>
      <TextField
        required
        margin="normal"
        fullWidth
        id="unit-number-input"
        name="unitNumber"
        label="Unit Number"
        type="number"
        value={formValues.unitNumber}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="emergency-contact-name-input"
        name="emergencyContactName"
        label="Emergency Contact Name"
        type="text"
        value={formValues.emergencyContactName}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="emergency-contact-phone-input"
        name="emergencyContactPhone"
        label="Emergency Contact Phone"
        helperText="10 digit phone number"
        type="text"
        value={formValues.emergencyContactPhone}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="emergency-contact-relation-input"
        name="emergencyContactRelation"
        label="Emergency Contact Relation"
        type="text"
        value={formValues.emergencyContactRelation}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        select
        margin="normal"
        fullWidth
        SelectProps={{
          native: true,
        }}
        required
        value={formValues.shirtSize}
        label="Shirt Size"
        name="shirtSize"
        helperText={
          <strong>
            {!(
              new Date(datastore.config.shirtRegCloseDate).setHours(
                0,
                0,
                0,
                0,
              ) < today.setHours(0, 0, 0, 0)
            )
              ? "T-shirts are an additional $" +
                datastore.config.shirtPrice +
                ". Select\n" +
                "'None' if you do not want to purchase a shirt."
              : "T-Shirt purchases are no longer available."}
          </strong>
        }
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      >
        <option value="select" disabled={true}>
          Select
        </option>
        <option value="None">None</option>
        {!(
          new Date(datastore.config.shirtRegCloseDate).setHours(0, 0, 0, 0) <
          today.setHours(0, 0, 0, 0)
        ) ? (
          <>
            <option value="XS">XS</option>
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
            <option value="Large">Large</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
            <option value="XXXL">XXXL</option>
          </>
        ) : null}
      </TextField>
      <Button
        onClick={() => {
          // On submit, check if username already exists
          ky.post(window._env_.REACT_APP_API_URL + "/check", {
            json: { configId: datastore.config.configId, ...formValues },
            timeout: window._env_.REACT_APP_TIMEOUT,
          })
            .json()
            .then((userExists) => {
              // Once we got a response, check fields for errors
              if (emailError) {
                setAlertLevel("error");
                setAlertMessage("Please make email address is vaild!");
                setAlertIsOpen(true);
              } else if (phoneError) {
                setAlertLevel("error");
                setAlertMessage("Please make sure your phone number is valid!");
                setAlertIsOpen(true);
              } else if (emergencyPhoneError) {
                setAlertLevel("error");
                setAlertMessage(
                  "Please make sure emergency contact phone number is valid!",
                );
                setAlertIsOpen(true);
              } else if (userExists) {
                setAlertLevel("error");
                setAlertMessage(
                  "A user with the email and name you provided already exists!",
                );
                setAlertIsOpen(true);
              } else if (
                Object.values(formValues).includes("") ||
                Object.values(formValues).includes("select")
              ) {
                setAlertLevel("error");
                setAlertMessage("Please make sure all fields are populated!");
                setAlertIsOpen(true);
              } else {
                window.sessionStorage.setItem(
                  "formValues",
                  JSON.stringify(formValues),
                );
                navigate("/register/review");
              }
            })
            .catch(() => {
              setAlertLevel("error");
              setAlertMessage(
                "Error: Could not check if username already exists.",
              );
              setAlertIsOpen(true);
            });
        }}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Continue to Payment
      </Button>

      <Stack justifyContent="center" alignItems="center" spacing={0.5}>
        <Typography variant="body1">
          <strong>Need Help?</strong>
        </Typography>
        <Link to="/faq" variant="body2">
          Frequently Asked Questions
        </Link>
        <Link to="/contact" variant="body2">
          Contact Us
        </Link>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertIsOpen}
        autoHideDuration={6000}
        onClose={() => setAlertIsOpen(false)}
      >
        <Alert onClose={() => setAlertIsOpen(false)} severity={alertLevel}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default Form;
