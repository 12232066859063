import React from "react";
import { Typography, Box, Container } from "@mui/material";

import Form from "../Form/Form";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const InfoRegister = ({ datastore }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box flexGrow={1}>
        <Container sx={{ pt: 4, textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            <strong>Broomball Registration</strong>
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Prices:
          </Typography>
          <Typography variant="h6" sx={{ mb: 0 }}>
            The price to play is <strong>${datastore.config.eventPrice}</strong>{" "}
            per person which does <strong>NOT</strong> include a t-shirt.
          </Typography>
          <Typography variant="h6" sx={{ mb: 0 }}>
            T-shirts are available for an additional $
            <strong>{datastore.config.shirtPrice}</strong>.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            T-shirts must be purchased with your registration before the cutoff
            date of: <strong>{datastore.config.shirtRegCloseDate}</strong>.
          </Typography>
        </Container>
        <Container sx={{ pb: 2 }}>
          <Form datastore={datastore} />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default InfoRegister;
