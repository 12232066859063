import React from "react";
import { Box, Typography } from "@mui/material";
import TextTransition from "react-text-transition";

const Loading = () => {
  const loadingTexts = ["", ".", "..", "..."];
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff",
        fontFamily: "Roboto, sans-serif",
        color: "#3c3c3c",
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          marginBottom: "1rem",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        Loading
        <TextTransition inline>
          {loadingTexts[index % loadingTexts.length]}
        </TextTransition>
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "1.5rem",
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        Please wait while we load the site.
      </Typography>
    </Box>
  );
};

export default Loading;
