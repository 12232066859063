const BroomballIcon = ({ color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 129"
    style={{ fill: color, width: "24px", height: "auto" }}
  >
    <g id="XMLID_00000084504264212035841730000016987523979708307600_">
      <g>
        <path
          d="M127,121c0,1-0.5,2-1.3,2.8l-2,1.9c-0.9,0.9-1.8,1.3-2.7,1.3c-0.1,0-0.1,0-0.2,0c-0.9-0.1-1.7-0.5-2.5-1.3L64,71.4
			L9.7,125.7c-0.8,0.8-1.6,1.2-2.5,1.3c-0.1,0-0.1,0-0.2,0c-0.9,0-1.8-0.4-2.7-1.3l-2-2C1.5,123,1,122,1,121c0-1,0.5-2,1.3-2.7
			L56.6,64l-2.7-2.7c-0.7,0.4-1.3,0.7-2,0.7c0,0-0.1,0-0.2,0c-0.9,0-1.8-0.5-2.5-1.3l-4-3.9C43.4,54.9,41.4,54,39,54
			c-3.7,0-11.3-2-14.6-4.2c-3.9-2.6-8.7-6.6-12.9-10.3c-4.2-3.7-7.8-7.2-9.3-8.8c-0.6-0.7-2.5-2.7,0-5.4l9.9-12.9
			c0.1-0.1,0.1-0.1,0.2-0.2l13-10C28-0.2,30,1.6,30.7,2.3c3.2,3,13.9,14.4,19.1,22.2C52,27.7,54,35.3,54,39c0,2.4,0.9,4.4,2.7,6.3
			l4,4c1.5,1.5,1.7,3.1,0.6,4.6l2.7,2.7l2.6-2.7c-1-1.5-0.8-3.1,0.6-4.6l4-4c1.8-1.9,2.7-3.8,2.7-6.3c0-3.7,2-11.3,4.2-14.6
			c5.2-7.8,16-19.2,19.1-22.2c0.7-0.6,2.7-2.5,5.4,0l12.9,9.9c0.1,0.1,0.1,0.1,0.2,0.2l10,13c2.5,2.7,0.6,4.7,0,5.3
			c-3,3.2-14.4,13.9-22.2,19.1C100.3,52,92.7,54,89,54c-2.4,0-4.4,0.9-6.3,2.7l-4,4C78,61.5,77.2,62,76.2,62c0,0-0.1,0-0.1,0
			c-0.7,0-1.4-0.2-2-0.7L71.4,64l54.3,54.3C126.5,119,127,120,127,121z M116,28c0-0.1,0-0.2-0.1-0.4l-2-5c-0.2-0.4-0.8-1.4-2-1.6
			c-0.7-0.1-1.6,0.1-2.6,1.3l-21,21c-0.3,0.3-0.4,0.7-0.2,1.1c0.2,0.4,0.5,0.6,0.9,0.6h0.1c0.1,0,0.2,0,0.3,0
			c4.3,0,14.9-1.5,25.4-14.4l0.1-0.2C115.3,30,116,29.2,116,28z M105.7,18.7c1.2-1,1.4-2,1.3-2.6c-0.2-1.2-1.2-1.9-1.6-2l-5-2
			c-0.1,0-0.2-0.1-0.4-0.1c-1.2,0-2,0.7-2.5,1.1l-0.2,0.1C84.2,24,82.9,34.8,83,38.9V39c0,0.4,0.2,0.8,0.6,0.9
			C83.7,40,83.9,40,84,40c0.3,0,0.5-0.1,0.7-0.3L105.7,18.7z M45,39v-0.1c0.1-4.1-1.2-14.9-14.4-25.7l-0.2-0.1
			C30,12.7,29.2,12,28,12c-0.1,0-0.2,0-0.4,0.1l-5,2c-0.4,0.1-1.4,0.9-1.6,2c-0.1,0.6,0.1,1.6,1.3,2.6l21,21
			c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.3,0,0.4-0.1C44.8,39.8,45,39.4,45,39z M39.9,44.4c0.2-0.4,0.1-0.8-0.2-1.1l-21-21
			c-0.6-0.9-1.6-1.4-2.6-1.3c-1.4,0.2-2,1.5-2,1.6c-0.7,1.7-1.3,3.3-2,5c0,0.1-0.1,0.3-0.1,0.4c-0.1,1.2,0.8,2.1,1.1,2.5l0.2,0.2
			C23.7,43.5,34.2,45,38.6,45c0.1,0,0.2,0,0.3,0l0.1,0C39.4,45,39.8,44.8,39.9,44.4z"
        />
      </g>
    </g>
  </svg>
);

export default BroomballIcon;
