import React from "react";
import { Link, Typography, Box } from "@mui/material";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const ContactUs = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box px={3} py={2} flexGrow={1} textAlign="center">
        <Box py={3}>
          <Typography variant="h4">Contact Us</Typography>
        </Box>
        <Box py={2}>
          <Typography variant="h5">
            Feel free to reach out to us with any questions or concerns.
          </Typography>
        </Box>
        <Box py={2}>
          <Typography variant="body1">
            For <strong>general</strong> questions about our broomball event,
            use the link{" "}
            <Link href="mailto:cabinet@crew324.com?subject=Crew 324 Broomball Inquiry">
              here
            </Link>
            .
          </Typography>
        </Box>
        <Box py={2}>
          <Typography variant="body1">
            For <strong>technical</strong> questions about our broomball event
            website or registration, use the link{" "}
            <Link href="mailto:admin@crew324.com?cc=advisor@crew324.com&subject=Crew 324 Broomball Technical Question">
              here
            </Link>
            .
          </Typography>
        </Box>
        <Box py={3}>
          <Typography variant="h4">Interested in joining our crew?</Typography>
        </Box>
        <Box py={3}>
          <Typography variant="body1">
            Check out our website for up-to-date information on our meeting
            times and locations{" "}
            <Link
              target="_blank"
              rel="noopener"
              href="https://www.crew324.com/"
            >
              here
            </Link>
            !
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ContactUs;
