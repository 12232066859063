import { Routes, Route, BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
// eslint-disable-next-line import/order
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";

import { CssBaseline } from "@mui/material";

import Home from "./components/Pages/Home";
import InfoRegister from "./components/Pages/InfoRegister";
import FaqPage from "./components/Pages/FaqPage";
import ContactUs from "./components/Pages/ContactUs";
import ReviewOrder from "./components/Pages/ReviewOrder";
import Participants from "./components/Pages/Participants";
import Files from "./components/Pages/Files";
import ConfigDatastore from "./components/Datastore/Datastore";
import TechnicalDifficulties from "./components/Pages/TechnicalDifficulties";
import Loading from "./components/Pages/Loading";
import Maintenance from "./components/Pages/Maintenance";
import Admin from "./components/Pages/Admin";
import { isPastDate } from "./utils/dateUtils";
import RegisterClosed from "./components/Pages/RegisterClosed";

const theme = createTheme({
  palette: {
    primary: {
      main: "#112233",
    },
    secondary: {
      main: "#445566",
    },
    info: {
      main: "#5a86a4",
    },
    background: {
      default: "#fff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#bdd0e3", // Or any color you want
        },
      },
    },
  },
});

const configDatastore = new ConfigDatastore();

const App = observer(() => {
  useEffect(() => {
    configDatastore.fetchConfig();
  }, []);
  if (
    configDatastore.isLoading === false &&
    configDatastore.isError === false
  ) {
    if (
      configDatastore.config.isMaintenance === true &&
      !window.location.href.includes("3000")
    ) {
      return <Maintenance />;
    } else {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home datastore={configDatastore} />} />
              {isPastDate(configDatastore.config.eventDate) ? (
                <Route path="register/info" element={<RegisterClosed />} />
              ) : (
                <>
                  <Route
                    path="register/info"
                    element={<InfoRegister datastore={configDatastore} />}
                  />
                  <Route
                    path="register/review"
                    element={<ReviewOrder datastore={configDatastore} />}
                  />
                </>
              )}
              <Route
                path="faq"
                element={<FaqPage datastore={configDatastore} />}
              />
              <Route
                path="files"
                element={<Files datastore={configDatastore} />}
              />
              <Route
                path="contact"
                element={<ContactUs datastore={configDatastore} />}
              />
              <Route
                path="participants"
                element={<Participants datastore={configDatastore} />}
              />
              <Route
                path="admin"
                element={<Admin datastore={configDatastore} />}
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      );
    }
  } else if (
    configDatastore.isLoading === true &&
    configDatastore.isError === false
  ) {
    return <Loading />;
  } else if (
    configDatastore.isLoading === false &&
    configDatastore.isError === true
  ) {
    return <TechnicalDifficulties />;
  }
});
export default App;
