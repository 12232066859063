import React, { useEffect, useState } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const PayPal = (props) => {
  const [price, setPrice] = useState(props.price);

  useEffect(() => {
    setPrice(props.price);
  }, [props.price]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": props.liveClientId,
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order
            .capture()
            .then((captureResponse) => {
              const captureId =
                captureResponse.purchase_units[0].payments.captures[0].id;
              props.handleClick(data.orderID, captureId);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      />
    </PayPalScriptProvider>
  );
};
export default PayPal;
