import React, { useState } from "react";
import {
  AppBar,
  Link,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import FolderIcon from "@mui/icons-material/Folder";
import FAQIcon from "@mui/icons-material/HelpOutline";
import EmailIcon from "@mui/icons-material/Email";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Logout } from "@mui/icons-material";

import VentureLogo from "../Icons/VentureLogo";
import BroomballIcon from "../Icons/BroomballIcon";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const links = [
    { text: "Home", href: "/", icon: <HomeIcon /> },
    {
      text: "Register",
      href: "/register/info",
      strong: true,
      icon: <BroomballIcon color="#757575" />,
    },
    { text: "Files", href: "/files", icon: <FolderIcon /> },
    { text: "FAQ", href: "/faq", icon: <FAQIcon /> },
    { text: "Contact Us", href: "/contact", icon: <EmailIcon /> },
  ];

  const adminLink = {
    text: "Admin",
    href: "/participants",
    icon: <AdminPanelSettingsIcon />,
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar position="static">
      <Toolbar
        variant="dense"
        sx={{
          backgroundColor: (theme) => theme.palette.info.main,
          color: (theme) => theme.palette.background.default,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <Box display="flex" flexDirection="column" height="100%">
            <List>
              {links.map(({ text, href, strong = false, icon }) => (
                <ListItem
                  button
                  key={text}
                  component={Link}
                  href={href}
                  onClick={handleDrawerClose}
                >
                  <ListItemIcon sx={{ mr: -2 }}>{icon}</ListItemIcon>
                  <ListItemText
                    primary={strong ? <strong>{text}</strong> : text}
                  />
                </ListItem>
              ))}
            </List>
            <Box flexGrow={1} /> {/* Spacer */}
            <List>
              <ListItem
                button
                key={adminLink.text}
                component={Link}
                href={adminLink.href}
                onClick={handleDrawerClose}
              >
                <ListItemIcon sx={{ mr: -2 }}>{adminLink.icon}</ListItemIcon>
                <ListItemText primary={adminLink.text} />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Typography variant="h6" sx={{ mr: 2 }}>
          Venture Crew 324
        </Typography>
        <VentureLogo height={20} width={20} fill="#006B3F" />
        <Box flexGrow={1} />
        {window.location.href.includes("participants") &&
          window.sessionStorage.getItem("isAdminLoggedIn") === "true" && (
            <Tooltip title="Logout" placement="bottom">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                size="small"
                onClick={() => {
                  window.sessionStorage.setItem("isAdminLoggedIn", false);
                  window.location.reload();
                }}
              >
                <Logout />
              </IconButton>
            </Tooltip>
          )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
