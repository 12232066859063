import { Grid, Typography } from "@mui/material";
import React from "react";

const Stat = ({ datastore, participantJson }) => {
  const frequencies = (array) =>
    array.reduce(
      (acc, item) =>
        array.filter((v) => v === acc).length >=
        array.filter((v) => v === item).length
          ? acc
          : item,
      null,
    );

  const dayWithMostRegistrants = (json) => {
    return frequencies(
      json.map((e) => new Date(e.registrationTime).setHours(0, 0, 0, 0)),
    );
  };

  const mostRegInDay = (json) => {
    return json
      .map((e) => new Date(e.registrationTime).setHours(0, 0, 0, 0))
      .filter((e) => e === dayWithMostRegistrants(json)).length;
  };

  const regOpenFor = () => {
    const startingDate = new Date(datastore.config.regOpenDate);
    const endingDate = new Date(datastore.config.regCloseDate);
    let todayDate = new Date(Date.now());
    if (todayDate.getTime() > endingDate.getTime()) {
      todayDate = endingDate;
    }
    const differenceInTime = todayDate.getTime() - startingDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const daysRemaining = () => {
    const endingDate = new Date(datastore.config.regCloseDate);
    const todayDate = new Date(Date.now());
    const differenceInTime = endingDate.getTime() - todayDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays >= 0 ? differenceInDays : 0;
  };

  const avgRegInDay = (json) => {
    const differenceInDays = regOpenFor();

    let arr = json.map((e) =>
      new Date(e.registrationTime).setHours(0, 0, 0, 0),
    );

    arr = Object.values(
      // eslint-disable-next-line no-sequences
      arr.reduce((prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev), {}),
    );

    const daysWithNoReg = differenceInDays - arr.length;

    for (let i = 0; i < daysWithNoReg; i++) {
      arr.push(0);
    }

    const average = arr.reduce((a, b) => a + b) / arr.length;
    return average.toLocaleString("fullwide", { maximumFractionDigits: 2 });
  };

  if (participantJson.length !== 0) {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-evenly"
        pb={2}
      >
        <Grid item xs={12} md={4} textAlign="center">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Total Participants:</strong> {participantJson.length}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Unique Units:</strong>{" "}
            {new Set(participantJson.map((e) => e.unitNumber)).size}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Total Income:</strong> $
            {participantJson
              .filter((e) => e.isRefunded === false)
              .map((e) => e.netPaymentAmount)
              .map((e) => parseFloat(e))
              .reduce((a, b) => a + b)
              .toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} textAlign="center">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Reg Open for:</strong> {regOpenFor()} Days
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Days Remaining:</strong> {daysRemaining()}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Registered Today:</strong>{" "}
            {
              participantJson.filter(
                (e) =>
                  new Date(e.registrationTime).setHours(0, 0, 0, 0) ===
                  new Date().setHours(0, 0, 0, 0),
              ).length
            }
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} textAlign="center">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Day w/ Most Reg:</strong>{" "}
            {new Date(dayWithMostRegistrants(participantJson)).toLocaleString(
              "en-US",
              {
                timeZone: "America/Los_Angeles",
                year: "numeric",
                month: "2-digit",
                day: "numeric",
              },
            )}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Most Reg in a Day:</strong> {mostRegInDay(participantJson)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>Avg Reg per Day:</strong> {avgRegInDay(participantJson)}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
export default Stat;
