import React from "react";

const VentureLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      width={props.width}
      viewBox="0 0 707.11 707.11"
    >
      <defs>
        <style>{`.cls-1{fill:` + props.fill + `;}.cls-2{fill:#fff;}`}</style>
      </defs>
      <rect
        className="cls-1"
        x="103.55"
        y="103.55"
        width="500"
        height="500"
        transform="translate(-146.45 353.55) rotate(-45)"
      />
      <rect className="cls-2" x="61.55" y="347.55" width="586" height="15" />
      <polygon
        className="cls-2"
        points="353.55 44.55 82.55 320.55 232.55 203.55 218.55 244.55 373.55 143.55 383.55 210.55 422.55 191.55 627.55 320.55 353.55 44.55"
      />
      <polygon
        className="cls-2"
        points="70.55 380.55 155.55 380.55 377.55 590.55 590.55 380.55 627.55 380.55 354.55 661.55 70.55 380.55"
      />
    </svg>
  );
};

export default VentureLogo;
