import React from "react";
import { Link, Typography, Box } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useMediaQuery } from "react-responsive";
import "react-pdf/dist/cjs/Page/TextLayer.css";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Files = ({ datastore }) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box px={3} py={2} flexGrow={1} textAlign="center">
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="h4">Broomball Files</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Document
            file={
              window._env_.REACT_APP_API_URL +
              "/getFile/" +
              datastore.config.configId +
              "/" +
              datastore.config.flyerFileId
            }
          >
            <Page
              renderAnnotationLayer={false}
              pageNumber={1}
              width={
                isBigScreen
                  ? 900
                  : isDesktopOrLaptop
                    ? 800
                    : isTablet
                      ? 600
                      : isMobile
                        ? 300
                        : 200
              }
            />
          </Document>
        </Box>
        <Box sx={{ textAlign: "center", p: 2 }}>
          <Typography variant="h6">
            Broomball Flier:{" "}
            <Link
              href={
                window._env_.REACT_APP_API_URL +
                "/getFile/" +
                datastore.config.configId +
                "/" +
                datastore.config.flyerFileId
              }
            >
              <strong>Download</strong>
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Document
            file={
              window._env_.REACT_APP_API_URL +
              "/getFile/" +
              datastore.config.configId +
              "/" +
              datastore.config.permissionSlipFileId
            }
          >
            <Page
              renderAnnotationLayer={false}
              pageNumber={1}
              width={
                isBigScreen
                  ? 900
                  : isDesktopOrLaptop
                    ? 800
                    : isTablet
                      ? 600
                      : isMobile
                        ? 300
                        : 200
              }
            />
          </Document>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Box>
            <Typography variant="h6">
              Crew 324 Permission Slip:{" "}
              <Link
                href={
                  window._env_.REACT_APP_API_URL +
                  "/getFile/" +
                  datastore.config.configId +
                  "/" +
                  datastore.config.permissionSlipFileId
                }
              >
                <strong>Download</strong>
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              *Only required if participant is under 18
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Files;
