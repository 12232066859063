import React, { useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Box,
  Backdrop,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { Stack } from "@mui/system";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PayPal from "../paypal/PayPal";

const ReviewOrder = ({ datastore }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = React.useState(
    JSON.parse(window.sessionStorage.getItem("formValues")),
  );
  const [promoCode, setPromoCode] = React.useState("");
  const [isDeveloper, setIsDeveloper] = React.useState(false);
  const [addFees, setAddFees] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("success");
  const [, setErr] = React.useState("");
  const [totalPrice, setTotalPrice] = React.useState(0.0);

  const firstUpdate = useRef(true);

  useEffect(() => {
    const basePrice =
      formValues.shirtSize === "None"
        ? datastore.config.eventPrice
        : datastore.config.eventPrice + datastore.config.shirtPrice;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setTotalPrice(basePrice);
      return;
    }

    let updatedPrice = basePrice;

    if (addFees) {
      updatedPrice += 2.0;
    } else if (totalPrice - basePrice === 2.0) {
      // This checks if we had added the $2 fee previously
      updatedPrice -= 2.0;
    }

    setTotalPrice(updatedPrice);
  }, [addFees, totalPrice]);

  useEffect(() => {
    if (promoCode === "dev") {
      setIsDeveloper(true);
      setTotalPrice(0.01);
    } else {
      setIsDeveloper(false);
      const basePrice =
        formValues.shirtSize === "None"
          ? datastore.config.eventPrice
          : datastore.config.eventPrice + datastore.config.shirtPrice;
      let updatedPrice = basePrice;

      if (addFees) {
        updatedPrice += 2.0;
      } else if (totalPrice - basePrice === 2.0) {
        // This checks if we had added the $2 fee previously
        updatedPrice -= 2.0;
      }
      setTotalPrice(updatedPrice);
    }
  }, [promoCode]);

  function delayAndGo(path, time) {
    setTimeout(() => navigate(path), time);
  }

  const handleClick = async (orderId, captureId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        window._env_.REACT_APP_API_URL + "/register",
        {
          method: "POST",
          body: JSON.stringify({
            participantInfo: {
              configId: datastore.config.configId,
              ...formValues,
            },
            paymentInfo: {
              method: "PayPal",
              amount: totalPrice.toString(),
              orderId,
              captureId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );

      setIsLoading(false);

      if (!response.ok) {
        setMessage("Registration Failed! Do NOT pay again! Please contact us!");
        setSeverity("error");
        setOpen(true);
      }

      setMessage(
        "Successfully registered. Please check your email for registration confirmation!",
      );
      setSeverity("success");
      setOpen(true);
      delayAndGo("/", 6000);
    } catch (err) {
      setErr(err.message);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh" // Ensure full viewport height
      sx={{ textAlign: "center" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Stack spacing={2} alignItems="center">
          <Paper sx={{ padding: "10px" }}>
            <CircularProgress size={59.5} color="background" />
            <Typography variant="h5">Registering your scout!</Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h5">Please Wait </Typography>
              <ThreeDots
                height={20}
                width={20}
                color="black"
                wrapperStyle={{ paddingTop: "12px", paddingLeft: "5px" }}
              />
            </Box>
          </Paper>
        </Stack>
      </Backdrop>

      <Header />
      <Box flexGrow={1}>
        <Typography variant="h4" pt={2}>
          <strong>Review Info:</strong>
        </Typography>
        <Box display="flex" justifyContent="center" pt={2}>
          <Table size="small" sx={{ maxWidth: "300px" }}>
            <TableBody>
              {[
                {
                  label: "Name",
                  value: `${formValues.firstName} ${formValues.lastName}`,
                },
                { label: "Birthdate", value: formValues.birthdate },
                { label: "Gender", value: formValues.gender },
                { label: "Email", value: formValues.email },
                { label: "Phone Number", value: formValues.phone },
                {
                  label: "Unit",
                  value: `${formValues.unitType} ${formValues.unitNumber}`,
                },
                {
                  label: "EC Name",
                  value: formValues.emergencyContactName,
                },
                {
                  label: "EC Relation",
                  value: formValues.emergencyContactRelation,
                },
                {
                  label: "EC Number",
                  value: formValues.emergencyContactPhone,
                },
                { label: "Shirt Size", value: formValues.shirtSize },
              ].map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>{item.label}:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{item.value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box my={2} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1" maxWidth={200}>
            Would you like to add <strong>$2.00</strong> to help cover
            processing fees?
          </Typography>
          <Checkbox onChange={(e) => setAddFees(e.target.checked)} />
        </Box>

        <Typography variant="body1">
          <strong>Total:</strong> ${totalPrice}
        </Typography>

        <Box my={2}>
          <TextField
            size="small"
            label="Promo Code"
            variant="outlined"
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <PayPal
            key={totalPrice}
            liveClientId={datastore.config.paypalClientId}
            handleClick={handleClick}
            price={totalPrice}
          />
        </Box>
        {isDeveloper && (
          <Box display="flex" justifyContent="center">
            <Button variant="outlined" onClick={handleClick}>
              Register
            </Button>
          </Box>
        )}
      </Box>
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReviewOrder;
