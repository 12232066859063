import React, { useState, useMemo, useEffect } from "react";
import { Typography, Box, TextField } from "@mui/material";
import JsonEditor from "@techfreaque/json-editor-react";
import ky from "ky";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "spectre.css/dist/spectre-icons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

const schema = {
  eventDomain: "string",
  eventName: "string",
  paypalClientId: "string",
  paypalClientSecret: "string",
  contactEmail: "string",
  receiptEmailAddress: "string",
  receiptEmailPassword: "string",
  receiptEmailSmtpHost: "string",
  receiptEmailSmtpPort: 0,
  logoFileId: 0,
  eventAddress: {
    name: "string",
    houseNumber: "string",
    road: "string",
    neighbourhood: "string",
    city: "string",
    postcode: "string",
    county: "string",
    state: "string",
    country: "string",
    countryCode: "string",
    googleMapsEmbedUrl: "string",
  },
  flyerFileId: 0,
  eventDate: "string",
  regOpenDate: "string",
  regCloseDate: "string",
  shirtRegCloseDate: "string",
  eventStartTime: "string",
  eventEndTime: "string",
  eventPrice: 0,
  shirtPrice: 0,
  discounts: [
    {
      promoCode: "string",
      price: 0,
    },
  ],
  faqs: [
    {
      title: "string",
      content: "string",
    },
  ],
  keyInfo: {
    who: ["string"],
    what: ["string"],
    when: ["string"],
    why: ["string"],
    how: ["string"],
  },
  emailReminders: ["string"],
  isMaintenance: true,
  isSandboxEnv: true,
};

function RenderJsonEditorsExample(props) {
  function saveEditors() {
    // use window.$JsonEditors to get all config values from all editors
    // access each editor by its name
    const editor = window.$JsonEditors["Editor-1"];
    const editorValues = editor.getValue();
    console.log("editorsData:", editorValues);
  }

  // Here you can implement your own way to render one or multiple <JsonEditor>
  // make sure the <JsonEditor> is rendered outside useMemo
  const renderedEditors = <JsonEditor {...props} onChange={saveEditors} />;

  return useMemo(
    () => (
      <>
        <button onClick={saveEditors}>Save</button>
        {renderedEditors}{" "}
      </>
    ),
    [props],
  );
}

function isProduction() {
  const host = window.location.host;
  const isLocalhost = host.includes("localhost");
  const isIpAddress = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d+)?$/.test(host);
  return !isLocalhost && !isIpAddress;
}

const Admin = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [config, setConfig] = useState({});

  useEffect(() => {
    if (isLogged === false) {
      return;
    } else {
      ky.post(window._env_.REACT_APP_API_URL + "/get/full/config", {
        body: isProduction()
          ? window.location.host
          : window._env_.REACT_APP_HOST_OVERRIDE,
        headers: {
          "Content-Type": "text/plain",
        },
        timeout: window._env_.REACT_APP_TIMEOUT,
      })
        .json()
        .then((response) => {
          setConfig(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isLogged]);

  if (isLogged === false) {
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box px={3} py={2} flexGrow={1} textAlign="center">
          <Box py={3}>
            <Typography variant="h4">Admin</Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h5">
              Please enter the admin password below:
            </Typography>
          </Box>
          <Box py={2}>
            <TextField
              variant="outlined"
              label="Password"
              type="password"
              onChange={(e) => {
                if (e.target.value === "crew324") {
                  setIsLogged(true);
                }
              }}
            />
          </Box>
        </Box>
        <Footer />
      </Box>
    );
  } else {
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box px={3} py={2} flexGrow={1} textAlign="center">
          <Box py={3}>
            <Typography variant="h4">Admin</Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h5">
              Carefully use the fields below to update the website
            </Typography>
          </Box>
          <Box py={2}>
            <RenderJsonEditorsExample
              editorName="Editor-1"
              schema={schema} // provide your own schema
              startval={config} // provide your own startval
              theme="bootstrap4"
              ajax={false}
              ajaxBase={undefined}
              ajaxCredentials={false}
              ajax_cache_responses={false}
              ajax_cache_buster={undefined}
              compact={true}
              disable_array_add={false}
              disable_array_delete={false}
              disable_array_delete_all_rows={false}
              disable_array_delete_last_row={false}
              disable_array_reorder={true}
              enable_array_copy={false}
              disable_collapse={true}
              disable_edit_json={true}
              disable_properties={true}
              array_controls_top={false}
              form_name_root="config"
              iconlib="spectre"
              remove_button_labels={false}
              // no_additional_properties={true}
              refs={{}}
              required_by_default={false}
              keep_oneof_values={true}
              show_errors="interaction"
              template="default"
              display_required_only={false}
              show_opt_in={false}
              prompt_before_delete={true}
              object_layout="grid"
              enum_source_value_auto_select={true}
              max_depth={0}
              use_default_values={true}
              urn_resolver={undefined}
              use_name_attributes={true}
              style={undefined}
            />
          </Box>
        </Box>
        <Footer />
      </Box>
    );
  }
};

export default Admin;
