import React from "react";
import { Box, Typography, Link } from "@mui/material";

const TechnicalDifficulties = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff",
        fontFamily: "Roboto, sans-serif",
        color: "#3c3c3c",
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          marginBottom: "2rem",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        Sorry, we're having technical difficulties.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "1.5rem",
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        Please refresh or try again later.
      </Typography>
      <Link
        href="mailto:jacob@adiumtech.com?subject=MB%20College%20Technical%20Difficulties"
        variant="body1"
        underline="none"
        sx={{
          marginTop: "2rem",
          fontSize: "1.2rem",
          fontWeight: 500,
          color: "#3c3c3c",
          transition: "color 0.3s ease",
          "&:hover": {
            color: "#007bff",
          },
        }}
      >
        Contact us
      </Link>
    </Box>
  );
};

export default TechnicalDifficulties;
