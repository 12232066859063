import React from "react";
import MUIDataTable from "mui-datatables";
import { Checkbox, Link, Typography } from "@mui/material";
import { utils, writeFileXLSX } from "xlsx";

import { calculateAge } from "../../utils/dateUtils";

const ParticipantTable = ({ datastore, participantJson }) => {
  const [filtered, setFiltered] = React.useState(false);

  const columns = [
    {
      name: "participantId",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "birthdate",
      label: "Birthdate",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "birthdate",
      label: "Age on Event Date",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return calculateAge(value, datastore.config.eventDate);
        },
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return capitalizeFirstLetter(value);
        },
      },
    },
    {
      name: "unitType",
      label: "Unit Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unitNumber",
      label: "Unit Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "emailAddress",
      label: "Participant Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Link href={"mailto:" + value}>Email</Link>;
        },
      },
    },
    {
      name: "emergencyContactName",
      label: "Emergency Contact Name",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "emergencyContactPhoneNumber",
      label: "Emergency Contact Phone Number",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "emergencyContactRelation",
      label: "Emergency Contact Relation",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "shirtSize",
      label: "Shirt Size",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentMethod",
      label: "Payment Method",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "paymentAmount",
      label: "Gross Payment",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return formatAsDollar(value);
        },
      },
    },
    {
      name: "netPaymentAmount",
      label: "Net Payment",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return formatAsDollar(value);
        },
      },
    },
    {
      name: "payPalFee",
      label: "Fees",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return formatAsDollar(value);
        },
      },
    },
    {
      name: "captureId",
      label: "Paypal ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "registrationTime",
      label: "Registration Date",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          });
        },
      },
    },
    {
      name: "isRefunded",
      label: "Refunded?",
      options: {
        display: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? "Yes" : "No";
        },
      },
    },
    {
      name: "shirtSize",
      label: "Received Shirt?",
      options: {
        display: true,
        sort: false,
        filter: false,
        customBodyRender: (val) => {
          if (val === "None") {
            return (
              <Typography variant="body2" sx={{ pl: 1 }}>
                N/A
              </Typography>
            );
          } else {
            return <Checkbox />;
          }
        },
      },
    },
  ];

  const capitalizeFirstLetter = ([first = "", ...rest]) => {
    return [first.toUpperCase(), ...rest].join("");
  };

  function formatAsDollar(input) {
    const number = parseFloat(input);
    if (isNaN(number)) {
      return "Invalid input"; // or handle as needed
    }

    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // Reshape data
      let formattedData = [];
      const keys = columns.map((c) => c.label);
      data.forEach((entry) => {
        const obj = {};
        keys.forEach((element, index) => {
          obj[element] = entry.data[index];
        });
        formattedData.push(obj);
      });
      // Convert to JSON to XLSX object
      const ws = utils.json_to_sheet(formattedData);
      // Determine widths for each column
      const fitToColumn = (data) => {
        const columnWidths = [];
        for (const property in data[0]) {
          columnWidths.push({
            wch: Math.max(
              property ? property.toString().length : 0,
              ...data.map((obj) =>
                obj[property] ? obj[property].toString().length : 0,
              ),
            ),
          });
        }
        return columnWidths;
      };
      // Set column widths
      ws["!cols"] = fitToColumn(formattedData);
      // Convert worksheet into workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      // Save workbook to file
      writeFileXLSX(
        wb,
        !filtered
          ? "Crew 324 2022 Broomball.xlsx"
          : "Crew 324 2022 Broomball Filtered.xlsx",
        { cellStyles: true },
      );
      // Return false to cancel original CSV download
      return false;
    },
    print: true,
    viewColumns: true,
    responsive: "standard",
    sortThirdClickReset: true,
    onFilterChange: (changedColumn, filterList) => {
      setFiltered(filterList.flat().length !== 0);
    },
  };

  return (
    <MUIDataTable
      data={participantJson.sort((a, b) => a.participantId - b.participantId)}
      columns={columns}
      options={options}
    />
  );
};
export default ParticipantTable;
